import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/auth/org"

export default {
	...commonApi(moduleName),
	// 上传文件
	uploadFile: (data, params) =>
		request("/document/attachment/user-audit-file", {
			method: "post",
			body: data,
			params
		})
}
