<template>
	<Modal
		:title="title"
		:value="visible"
		width="1000"
		:transfer="type === 'modal' ? false : true"
		:inner="type === 'modal' ? true : false"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			:model="formData"
			ref="form"
			label-position="top"
			:rules="rules"
			class="organization-add"
		>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="类型" prop="type">
						<Select
							v-model="formData.type"
							@on-change="typeChange"
							placeholder="请选择"
							v-if="!this.updateId"
						>
							<Option
								:value="list.id"
								v-for="list in typeList"
								:key="list.id"
								:label="list.name"
							></Option>
						</Select>
						<span v-else>{{ formData.typeName }}</span>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="全称" prop="name">
						<Input
							:maxlength="128"
							v-model.trim="formData.name"
							placeholder="请输入组织名称"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="简称" prop="nickname">
						<Input v-model.trim="formData.nickname" placeholder="请输入简称" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="设置管理员" prop="managerName">
						<Select
							v-model="formData.orgAdminId"
							filterable
							clearable
							:label-in-value="true"
							@on-change="changeManager"
						>
							<Option
								v-for="(option, index) in userOptions"
								:value="option.value"
								:key="index"
								>{{ option.label }}</Option
							>
						</Select>
					</FormItem>
				</Col>

				<Col span="12">
					<FormItem label="联系人" prop="contact">
						<Input v-model.trim="formData.contact" placeholder="请输入联系人" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="联系电话" prop="phone">
						<Input v-model.trim="formData.phone" placeholder="请输入联系电话" />
					</FormItem>
				</Col>

				<Col span="12">
					<FormItem label="Email" prop="email">
						<Input v-model.trim="formData.email" placeholder="请输入Email" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="网址" prop="address">
						<Input v-model.trim="formData.address" placeholder="请输入网址" />
					</FormItem>
				</Col>
				<Col span="24" v-if="formData.type === '104'">
					<FormItem label="LOGO" prop="logo">
						<Upload
							:before-upload="handleBeforeUpload"
							:show-upload-list="false"
							accept="image/jpg, image/jpeg, image/png"
							action="/"
						>
							<template v-if="formData.logo">
								<p style="margin-bottom: 10px">
									<Button>修改LOGO</Button>
								</p>
								<img :src="formData.logo" class="preview" />
							</template>
							<div class="upload-btn-wrap" v-else>
								<Button type="primary" shape="circle" icon="md-add"></Button>
								<p>上传LOGO</p>
							</div>
						</Upload>
					</FormItem>
					<p v-if="fileReading" style="color: red">文件读取中......</p>
				</Col>
				<Col span="24" v-if="isShowBusinessLicence">
					<FormItem
						prop="businessLicence"
						:required="true"
						:show-message="false"
						label="营业执照"
					>
						<Row :gutter="32">
							<Col
								:span="12"
								class="img-preview-wrap"
								v-for="(item, index) in formData.businessLicence"
								:key="index"
							>
								<img :src="getPreviewImg(item)" class="preview" />
								<Icon type="md-close" @click="delBusinessLicenceImg(index)" />
							</Col>
							<Col :span="12">
								<Upload
									style="display: inline-block; width: 100%"
									action="/"
									accept="image/jpg, image/jpeg, image/png"
									:show-upload-list="false"
									:before-upload="file => getMD5(file, 'businessLicence')"
								>
									<div
										class="upload-btn-wrap"
										v-if="
											!formData.businessLicence ||
											formData.businessLicence.length < 6
										"
									>
										<Button
											type="primary"
											shape="circle"
											icon="md-add"
										></Button>
										<p>上传营业执照</p>
									</div>
								</Upload>
							</Col>
						</Row>
					</FormItem>
				</Col>
			</Row>
			<ExtraForm
				@extraValueChange="(key, value) => handleExtraValueChange(key, value)"
				@updateList="updateList"
				:list="extraList"
				:data="formData"
			></ExtraForm>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button
				type="primary"
				@click="onOk"
				:disabled="fileReading"
				:loading="loading"
				>确定
			</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/organization"
import { formDataToMD5 } from "@/utils/util"
import ExtraForm from "@/components/BusinessComponents/ExtendForm/ExtraForm.vue"
import ExtraMixin from "@/mixins/ExtraMixin"
import publicApi from "@/api/public"

const { publicGetuserList } = publicApi
const { apiGet, apiAdd, apiUpdate, uploadFile } = api

export default {
	name: "Add",
	props: ["visible", "typeList", "updateId", "type"],
	mixins: [ExtraMixin],
	data() {
		return {
			loading: false,
			formData: {
				businessLicence: []
			},
			title: "",
			file: null,
			rules: {
				name: {
					required: true,
					message: "组织名称不能为空",
					trigger: "blur"
				},
				type: {
					required: true,
					message: "组织类型不能为空",
					trigger: "change"
				},
				phone: {
					...this.rules.mobileNumber,
					message: "手机号码格式不正确",
					trigger: "blur"
				},
				email: [
					{
						type: "email",
						message: "邮箱格式不正确",
						trigger: "blur"
					}
				]
				// address: {
				//   ...this.rules.address,
				//   message: '输入格式例如：http://www.baidu.com',
				//   trigger: 'blur',
				// },
			},
			fileReading: false,
			extraList: [],
			isShowBusinessLicence: false,
			/* 设置管理员 */
			userOptions: [],
			userList: []
		}
	},
	mounted() {},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {
					businessLicence: []
				}
				this.title = "新增组织"
				if (this.updateId) {
					this.loading = true
					this.title = "修改组织"
					this.getuserList()
					this.loading = false
				}
			}
		}
	},
	methods: {
		/* 根据id获取详情信息 */
		getData() {
			this.$asyncDo(async () => {
				const res = await apiGet({ id: this.updateId })
				if (res) {
					this.isShowBusinessLicence =
						["101", "102", "103"].indexOf(res.data.type) > -1
					this.formData = {
						...this.formData,
						...res.data,
						businessLicence: res.data.businessLicence
							? res.data.businessLicence.split(",")
							: [],
						...JSON.parse(res.data.extraData)
					}
				}
			})
		},
		/* 根据组织获取用户列表 */
		getuserList() {
			this.$asyncDo(async () => {
				const res = await publicGetuserList({
					orgId: this.updateId
				})
				if (res) {
					const list = res.data.map(item => ({
						value: item.id,
						label: item.realName
					}))
					this.userList = list
					this.userOptions = list
				}
				this.getData()
			})
		},
		/* 切换管理员 */
		changeManager(val) {
			if (val) {
				this.formData.orgAdminId = val.value
				this.formData.orgAdminName = val.label
			} else {
				this.userOptions = this.userList
				this.formData.orgAdminId = ""
				this.formData.orgAdminName = ""
			}
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			if (["101", "102", "103"].indexOf(this.formData.type) > -1) {
				if (
					!this.formData.businessLicence.length &&
					this.type !== "personalInfo"
				) {
					this.$Message.error("请上传营业执照！")
					return false
				}
			}

			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						this.formData.extraData = null
						if (this.updateId) {
							res = await apiUpdate({
								...this.formData,
								id: this.updateId,
								businessLicence: this.formData.businessLicence.join(","),
								extraData: this.getExtraData()
							})
						} else {
							res = await apiAdd({
								...this.formData,
								businessLicence: this.formData.businessLicence.join(","),
								extraData: this.getExtraData()
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
			return true
		},
		handleBeforeUpload(file) {
			this.file = file
			const reader = new FileReader()
			this.fileReading = true
			reader.readAsDataURL(file)
			reader.onload = e => {
				this.formData.logo = e.target.result
			}
			reader.onloadend = () => {
				this.fileReading = false
			}
			return false
		},
		updateList(obj) {
			this.extraList = obj
		},
		// md5
		getMD5(file, type) {
			const suffix = file.name.split(".")
			const allowAccept = ["jpg", "jpeg", "png"]
			if (allowAccept.indexOf(suffix[suffix.length - 1].toLowerCase()) <= -1) {
				this.$Message.error("上传文件格式不正确！")
				return false
			}
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, { md5: md5Str }, type)
			})
			return false
		},
		// 上传文件
		uploadFile(file, params) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				const res = await uploadFile(fd, params)
				if (res) {
					this.$Message.success("上传成功!")
					const businessLicence = [...this.formData.businessLicence]
					businessLicence.push(res.data)
					this.$set(this.formData, "businessLicence", businessLicence)
				}
			})
		},
		// 获取图片
		getPreviewImg(fileId) {
			return `${this.$baseUrl}/document/attachment/user-audit-file/${fileId}`
		},

		typeChange(value) {
			this.isShowBusinessLicence = ["101", "102", "103"].indexOf(value) > -1
		},

		// 删除上传图片
		delBusinessLicenceImg(i) {
			let businessLicence = [...this.formData.businessLicence]
			businessLicence = businessLicence.filter((item, index) => index !== i)
			this.$set(this.formData, "businessLicence", businessLicence)
		}
	},
	components: {
		ExtraForm
	},
	created() {
		this.getExtraList("ORG")
	}
}
</script>

<style lang="less">
@import "../../../my-theme/color.less";
.organization-add {
	.file-name {
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		padding-right: 30px;
		height: 25px;
		line-height: 25px;
		font-size: 14px;
		.close {
			position: absolute;
			right: 0;
			top: 0;
			display: block;
			width: 25px;
			height: 25px;
			text-align: center;
			line-height: 25px;
			cursor: pointer;
			&:hover {
				color: @error-color;
			}
		}
	}
	.ivu-upload-select {
		width: 100% !important;
		.preview {
			max-width: 100%;
		}
	}
	.upload-btn-wrap {
		width: 100%;
		background-color: #f9f9f9;
		padding: 80px 0 50px 0;
		text-align: center;
		cursor: pointer;
		> p {
			margin-top: 15px;
			color: @primary-color;
		}
	}
	.img-preview-wrap {
		display: inline-block;
		position: relative;
		margin-bottom: 15px;
		img {
			width: 100%;
			height: 210px;
		}
		.ivu-icon.ivu-icon-md-close {
			position: absolute;
			left: 450px;
			top: 10px;
			font-size: 20px;
			color: #999;
			cursor: pointer;
			&:hover {
				color: red;
			}
		}
	}
}
</style>
