<template>
	<Modal
		title="查看组织"
		:value="visible"
		width="1000"
		:transfer="type === 'modal' ? false : true"
		:inner="type === 'modal' ? true : false"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="viewData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="类型">
						{{ viewData.typeName || "--" }}
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="全称">
						{{ viewData.name || "--" }}
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="简称">
						{{ viewData.nickname || "--" }}
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="管理员">
						{{ viewData.orgAdminName || "--" }}
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="联系人">
						{{ viewData.contact || "--" }}
					</FormItem>
				</Col>

				<Col span="12">
					<FormItem label="联系电话">
						{{ viewData.phone || "--" }}
					</FormItem>
				</Col>

				<Col span="12">
					<FormItem label="Email">
						{{ viewData.email || "--" }}
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="网址">
						{{ viewData.address || "--" }}
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="更新人">
						{{ viewData.updatedBy || "--" }}
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="更新时间">
						{{ viewData.updatedTime || "--" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="描述">
						{{ viewData.descr || "--" }}
					</FormItem>
				</Col>
				<Col span="24" v-if="viewData.type === '104'">
					<FormItem label="LOGO">
						<img
							v-if="viewData.logo"
							style="max-width: 100%"
							:src="viewData.logo"
						/>
						<span v-else>--</span>
					</FormItem>
				</Col>
				<Col span="24" v-if="['101', '102', '103'].indexOf(viewData.type) > -1">
					<Col span="24">
						<FormItem
							prop="businessLicence"
							:required="true"
							:show-message="false"
							label="营业执照"
						>
							<Row>
								<Col span="24">
									<Row :gutter="32" v-if="viewData.businessLicence.length">
										<Col
											:span="12"
											v-for="(item, index) in viewData.businessLicence"
											:key="index"
										>
											<img
												style="width: 100%; height: 210px"
												:src="getPreviewImg(item)"
												class="preview"
											/>
										</Col>
									</Row>
									<div class="upload-btn-wrap" v-else>
										<p>--</p>
									</div>
								</Col>
							</Row>
						</FormItem>
					</Col>
				</Col>
			</Row>
			<ExtraForm :list="extraList" :data="viewData" formType="view"></ExtraForm>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/organization"
import ExtraForm from "@/components/BusinessComponents/ExtendForm/ExtraForm.vue"
import ExtraMixin from "@/mixins/ExtraMixin"

const { apiGet } = api
export default {
	name: "ViewDetail",
	props: ["viewId", "visible", "type"],
	components: {
		ExtraForm
	},
	mixins: [ExtraMixin],
	data() {
		return {
			viewData: {
				businessLicence: []
			},
			loading: false,
			extraList: []
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		// 获取图片
		getPreviewImg(fileId) {
			return `${this.$baseUrl}/document/attachment/user-audit-file/${fileId}`
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.viewData = {}
				if (this.viewId) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.viewId })
						if (res) {
							this.viewData = {
								...this.viewData,
								...res.data,
								businessLicence: res.data.businessLicence
									? res.data.businessLicence.split(",")
									: [],
								...JSON.parse(res.data.extraData)
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	created() {
		this.getExtraList("ORG")
	}
}
</script>

<style scoped></style>
